import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { HeaderContextProvider } from 'ui/context/header'
import Head from 'next/head'
import { IntercomProvider } from 'react-use-intercom'
import 'ui/css/global.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import TagManager from 'react-gtm-module'
import AccessibeScript from 'ui/components/organisms/c-accessibe-script'
import OneTrustScript from 'ui/components/organisms/c-one-trust'
import { useSiteInfo } from 'shared'
import { useRouter } from 'next/router'
import { AppContextProvider } from '../context/app'

declare global {
  export interface Window {
    analytics: any
    OneTrust: any
  }
}

let isGtmInitialized = false

const App = ({ Component, pageProps }: AppProps) => {
  const siteInfo = useSiteInfo()

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      window.OneTrust?.initializeCookiePolicyHtml()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (
      siteInfo.isGE &&
      process.env.NODE_ENV !== 'production' &&
      !isGtmInitialized
    ) {
      const gtmIdGE = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_1_GERMAN
      const gtmId2GE = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_2_GERMAN
      gtmIdGE && TagManager.initialize({ gtmId: gtmIdGE })
      gtmId2GE && TagManager.initialize({ gtmId: gtmId2GE })
      isGtmInitialized = true
    }
  }, [])

  useEffect(() => {
    if (
      siteInfo.isGE &&
      process.env.NODE_ENV !== 'production' &&
      !isGtmInitialized
    ) {
      const gtmIdGE = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_1_GERMAN
      const gtmId2GE = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_2_GERMAN
      gtmIdGE && TagManager.initialize({ gtmId: gtmIdGE })
      gtmId2GE && TagManager.initialize({ gtmId: gtmId2GE })
      isGtmInitialized = true
    }
  }, [])

  useEffect(() => {
    const gtmIdGE = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_1_GERMAN
    const gtmId2GE = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_2_GERMAN
    if (siteInfo.isGE && (gtmIdGE || gtmId2GE) && !isGtmInitialized) {
      gtmIdGE && TagManager.initialize({ gtmId: gtmIdGE })
      gtmId2GE && TagManager.initialize({ gtmId: gtmId2GE })
      isGtmInitialized = true
    }
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const gtmId1 = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_1
      const gtmId2 = process.env.NEXT_PUBLIC_DOCTORS_GMTD_ID_2
      gtmId1 && TagManager.initialize({ gtmId: gtmId1 })
      gtmId2 && TagManager.initialize({ gtmId: gtmId2 })
    }
  }, [])

  return (
    <AppContextProvider initialState={pageProps.initialAppContext}>
      <IntercomProvider
        appId={siteInfo.isUS ? process.env.NEXT_PUBLIC_INTERCOM_ID ?? '' : ''}
        autoBoot
      >
        <HeaderContextProvider initTransparent={!siteInfo.isUS}>
          <Head>
            <link
              rel="icon"
              type="image/x-icon"
              href={`/${siteInfo?.layout}/favicon.ico`}
            ></link>
          </Head>
          <Component {...pageProps} />
          {(siteInfo.isUS || siteInfo.isCA || siteInfo.isFC) && (
            <AccessibeScript />
          )}
          <OneTrustScript />
        </HeaderContextProvider>
      </IntercomProvider>
    </AppContextProvider>
  )
}

export default appWithTranslation(App)
