import React, { PropsWithChildren, useReducer } from 'react'
import { useSiteInfo } from 'shared'
import { CraftDoctor, CraftLocation, CraftRegion } from '../craft-types'
import useSettings from '../hooks/use-settings'
import { doctorContextReducer } from './reducers/doctor'
import { Doctor, doctorsContextReducer } from './reducers/doctors'
import { locationContextReducer } from './reducers/location'
import { locationsContextReducer, Location } from './reducers/locations'
import { queryContextReducer, QueryState } from './reducers/query'
import { radiusContextReducer } from './reducers/radius'

export type AppState = {
  query: QueryState
  regions: Array<CraftRegion>
  radius: {
    default: string
    options: Array<{
      label: string
      value: string
    }>
  }
  doctors: Array<Doctor>
  locations: Array<Location>
  doctor: CraftDoctor
  location: CraftLocation
  recentlyRequestedDoctors: string[]
}

type Action = {
  type: string
  payload: AppState
}

export type AppContextType = {
  state: AppState
  dispatch: React.Dispatch<Action>
  updateQuery: (payload: QueryState) => Promise<void>
  updateDoctor: (slug: string) => Promise<void>
  updateLocation: (slug: string) => Promise<void>
  updateDoctorRequestedCallback: (slug: string) => Promise<void>
  clearRecentlyRequestedDoctors: () => void
}

type Props = {
  children: React.ReactNode
  initialState?: AppState
}

export const defaultState: AppState = {
  query: {
    lat: 0,
    lng: 0,
    q: '',
    radius: '',
    sort: '',
    type: '',
  },
  regions: [],
  radius: {
    default: '',
    options: [],
  },
  doctors: [],
  locations: [],
  doctor: {},
  location: {},
  recentlyRequestedDoctors: [],
}

export const AppContext = React.createContext<AppContextType | null>(null)

export const AppContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  initialState,
}) => {
  const settings = useSettings()
  const siteInfo = useSiteInfo()

  const reducer = (state: AppState, action: Action): AppState => {
    if (action.type === 'update') {
      return { ...state, ...action.payload }
    }

    return state
  }

  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...initialState,
  })

  const updateQuery = async (payload: QueryState) => {
    const query = await queryContextReducer(settings, siteInfo, payload)
    let newState = { ...state, query: { ...state.query, ...query } }

    const changedKeys: Array<string> = []

    type StateQueryKey = keyof typeof state.query
    type PayloadKey = keyof typeof payload

    Object.keys(payload).forEach((key) => {
      if (state.query[key as StateQueryKey] !== payload[key as PayloadKey]) {
        changedKeys.push(key)
      }
    })

    if (
      changedKeys.some((v) => ['q', 'lat', 'lng', 'sort', 'radius'].includes(v))
    ) {
      const radius = await radiusContextReducer(settings, siteInfo, newState)
      newState = { ...newState, radius }

      const doctors = await doctorsContextReducer(settings, siteInfo, newState)
      newState = { ...newState, doctors }

      const locations = await locationsContextReducer(
        settings,
        siteInfo,
        newState
      )
      newState = { ...newState, locations }
    }

    dispatch({
      type: 'update',
      payload: newState,
    })
  }

  const updateDoctor = async (slug: string) => {
    const res = await doctorContextReducer(settings, siteInfo, slug)

    const newState = {
      ...state,
      doctor: res,
    }

    dispatch({
      type: 'update',
      payload: newState,
    })
  }

  const updateLocation = async (slug: string) => {
    const res = await locationContextReducer(siteInfo, slug)

    const newState = {
      ...state,
      location: res,
    }

    dispatch({
      type: 'update',
      payload: newState,
    })
  }

  const updateDoctorRequestedCallback = async (slug: string) => {
    const newState = {
      ...state,
      recentlyRequestedDoctors: [...state.recentlyRequestedDoctors, slug],
    }

    dispatch({
      type: 'update',
      payload: newState,
    })
  }

  const clearRecentlyRequestedDoctors = () => {
    const recentlyRequestedDoctors = [...state.recentlyRequestedDoctors]
    const indexToRemove = 0

    if (recentlyRequestedDoctors.length > 0) {
      recentlyRequestedDoctors.splice(indexToRemove, 1)
    }

    const newState = {
      ...state,
      recentlyRequestedDoctors,
    }

    dispatch({
      type: 'update',
      payload: newState,
    })
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        updateQuery,
        updateDoctor,
        updateLocation,
        updateDoctorRequestedCallback,
        clearRecentlyRequestedDoctors,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
