import { SiteInfo } from 'shared'
import { CraftDoctor, CraftDoctorLocation } from '../../craft-types'
import graphql from '../../helpers/graphql'
import { Settings } from '../../settings-types'

const sortByRank = (a: CraftDoctorLocation, b: CraftDoctorLocation) => {
  if ((a.locationPriority || 99999) < (b.locationPriority || 99999)) {
    return -1
  }

  if ((a.locationPriority || 99999) > (b.locationPriority || 99999)) {
    return 1
  }

  return 0
}

export const doctorContextReducer = async (
  settings: Settings,
  siteInfo: SiteInfo | null,
  slug: string
) => {
  const graphqlInstance = graphql(siteInfo)

  try {
    const res = await graphqlInstance.doctor(slug)
    let payload: CraftDoctor = res.data?.entry

    if (
      settings.search === 'geocoding' &&
      payload.doctorLocations &&
      payload.doctorLocations.length > 1
    ) {
      const doctorLocationsSorted = [...payload.doctorLocations].sort(
        sortByRank
      )
      payload = {
        ...payload,
        doctorLocations: doctorLocationsSorted,
      }
    }
    return payload
  } catch (error) {
    console.error(error)
  }

  return {}
}
