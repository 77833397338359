import { SiteInfo } from 'shared'
import { createClient, gql } from '@urql/core'
import * as CONST from 'ui/constants/index'
import { doctorsEvoClient, doctorsIclClient } from '../graphql/clients'

const queries = {
  doctor: gql`
    query Entry($slug: String, $site: [String]) {
      entry(section: "doctors", slug: [$slug], site: $site) {
        ... on doctors_doctors_Entry {
          id
          slug
          title
          doctorPicture {
            url
          }
          doctorGallery {
            thumb: url @transform(width: 220, height: 220, immediately: true)
            image: url
              @transform(
                width: 800
                height: 600
                immediately: true
                mode: "fit"
              )
          }
          doctorVideo {
            embeddedAsset {
              code
            }
          }
          doctorBiography
          doctorCertificates {
            text
          }
          doctorEducation {
            text
          }
          doctorSocial {
            text
            link
            icon
          }
          doctorSpecialist
          doctorEmail
          doctorLocations {
            ... on locations_locations_Entry {
              id
              slug
              title
              locationPriority
              locationTier
              locationPicture {
                url
              }
              locationEmail
              locationPhone {
                url
              }
              locationWebsite {
                url
              }
              locationContact {
                url
              }
              locationAddress
              locationLatitude
              locationLongitude
              locationLanguages {
                text
              }
              locationHours {
                text
              }
              locationRegions {
                id
                slug
                title
              }
            }
          }
          doctorReviewId
          doctorReviewType
          doctorGoogleReviewLink
          doctorCustomReviewText
          doctorCustomReviewLink
          freeConsultation
          consultationUrl
          customFormVerbiage
          seomatic(asArray: true) {
            metaTitleContainer
            metaTagContainer
            metaLinkContainer
            metaScriptContainer
            metaJsonLdContainer
          }
        }
      }
    }
  `,
  location: gql`
    query Entry($slug: String, $site: [String]) {
      entry(section: "locations", slug: [$slug], site: $site) {
        ... on locations_locations_Entry {
          id
          slug
          title
          locationPicture {
            url
          }
          locationGallery {
            thumb: url @transform(width: 220, height: 220, immediately: true)
            image: url
              @transform(
                width: 800
                height: 600
                immediately: true
                mode: "fit"
              )
          }
          locationVideo {
            embeddedAsset {
              code
            }
          }
          locationEmail
          locationPhone {
            url
          }
          locationWebsite {
            url
          }
          locationContact {
            url
          }
          locationAddress
          locationBiography
          locationLanguages {
            text
          }
          locationPayments {
            text
          }
          locationClinicSpecific {
            text
          }
          locationSocial {
            text
            link
            icon
          }
          locationHours {
            text
          }
          locationLatitude
          locationLongitude
          locationDoctors(
            orderBy: "doctorSpecialist DESC, doctorPriority ASC"
          ) {
            ... on doctors_doctors_Entry {
              id
              slug
              title
              doctorPriority
              doctorSpecialist
              doctorPicture {
                url
              }
              doctorEmail
            }
          }
          locationRegions {
            id
            slug
            title
          }
          locationReviewId
          locationReviewType
          locationGoogleReviewLink
          locationCustomReviewText
          locationCustomReviewLink
          seomatic(asArray: true) {
            metaTitleContainer
            metaTagContainer
            metaLinkContainer
            metaScriptContainer
            metaJsonLdContainer
          }
        }
      }
    }
  `,
  regions: gql`
    query Entry($site: [String]) {
      entries(section: "regions", site: $site, orderBy: "title ASC") {
        ... on regions_regions_Entry {
          id
          slug
          title
          regionLocations {
            id
          }
        }
      }
      globalSet(handle: "global", site: $site) {
        ... on global_GlobalSet {
          featuredRegions {
            ... on regions_regions_Entry {
              id
              slug
              title
            }
          }
        }
      }
    }
  `,
}

const graphql = (siteInfo: SiteInfo | null) => {
  let client: ReturnType<typeof createClient> = {} as ReturnType<
    typeof createClient
  >

  if (siteInfo?.layout === CONST.SITE_LAYOUT.EVO) {
    client = doctorsEvoClient
  } else if (siteInfo?.layout === CONST.SITE_LAYOUT.ICL) {
    client = doctorsIclClient
  }

  const doctor = (slug: string) => {
    return client.query(queries.doctor, {
      slug,
      site: siteInfo?.handle,
    })
  }

  const location = (slug: string) => {
    return client.query(queries.location, {
      slug,
      site: siteInfo?.handle,
    })
  }

  const regions = (site: string) => {
    return client.query(queries.regions, {
      site,
    })
  }

  return { regions, doctor, location }
}

export default graphql
