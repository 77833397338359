// import React, { PropsWithChildren, useReducer } from 'react'
import mapbox from '../../helpers/mapbox'
import { Settings } from '../../settings-types'
import { SiteInfo } from 'shared'

export type QueryState = {
  lat?: number
  lng?: number
  q?: string
  radius?: string
  sort?: string
  type?: string
  mapboxPlainText?: string[]
}

type MapboxContextItem = {
  text: string
}

export const queryContextReducer = async (
  settings: Settings,
  siteInfo: SiteInfo | null,
  payload: QueryState
) => {
  const mapboxInstance = mapbox(settings, siteInfo)

  if (payload.q) {
    try {
      const res = await mapboxInstance.forwardGeocode(payload.q)
      const [lng, lat] = res.features[0].center
      const mapboxPlainText: string[] = res.features
        .find((feature: any) => feature.context)
        .context.map((item: MapboxContextItem) => {
          return item.text
        })

      mapboxPlainText.push(res.features[0].text)
      payload = {
        ...payload,
        lat,
        lng,
        mapboxPlainText,
      }
    } catch (error) {
      payload = { ...payload, lat: 0, lng: 0 }
      console.error(error)
    }

    return payload
  }

  return {}
}
