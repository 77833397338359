import { SiteInfo } from 'shared'
import algolia from '../../helpers/algolia'
import { Settings } from '../../settings-types'
import { AppState } from '../app'

export const radiusContextReducer = async (
  settings: Settings,
  siteInfo: SiteInfo | null,
  appContext: AppState
) => {
  if (appContext.query.lat && appContext.query.lng) {
    const algoliaInstance = algolia(settings, siteInfo, appContext)
    const isLA = siteInfo?.isLA

    try {
      const { hits } = await algoliaInstance.unbounded()
      const hit = hits.pop()
      let radius = 0
      if (hit?._rankingInfo?.geoDistance) {
        radius = Math.ceil(hit._rankingInfo.geoDistance / settings.unit.value)
      }

      const options = isLA ? settings.radius.options : settings.radius.options.filter(
        (v) => Number(v.value) > radius
      )
      const last = settings.radius.options.slice(-1)[0]
      const lastValue = parseInt(last.value)

      let i = Math.max(2, Math.ceil(radius / lastValue))
      while (options.length < 3) {
        options.push({
          label: String(lastValue * i),
          value: String(lastValue * i),
        })
        i++
      }

      const selected =
        options.find((v) =>  {
          if (isLA && radius > Number(settings.radius.default)) {
            return Number(v.value) > radius
          }
          return v.label === settings.radius.default
        }) || options[0]
      let defaultOverride = null
      if (appContext.query.mapboxPlainText?.length) {
        const findOverride = settings.radius.queryOverride.find((item) => {
          const found = appContext.query.mapboxPlainText?.find((ptItem) => {
            return ptItem === item.text
          })
          return !!found
        })
        if (findOverride) {
          defaultOverride = findOverride.default
        }
      }
      const payload = {
        default: defaultOverride ?? String(selected.label),
        options,
      }

      return payload
    } catch (error) {
      console.error(error)
    }
  }

  return {
    default: '',
    options: [],
  }
}
