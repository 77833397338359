import { SiteInfo } from 'shared'
import { CraftLocation } from '../../craft-types'
import graphql from '../../helpers/graphql'

export const locationContextReducer = async (
  siteInfo: SiteInfo | null,
  slug: string
) => {
  const graphqlInstance = graphql(siteInfo)

  try {
    const res = await graphqlInstance.location(slug)
    const payload: CraftLocation = res.data?.entry
    return payload
  } catch (error) {
    console.error(error)
  }

  return {}
}
