import { SiteInfo } from 'shared'
import algolia from '../../helpers/algolia'
import avatar from '../../helpers/avatar'
import { Settings } from '../../settings-types'
import { AppState } from '../app'
import { shuffle } from './doctors'

export type Location = {
  id: number
  slug: string
  title: string
  avatar: string
  address: string
  doctors: Array<number>
  regions: Array<number>
  _geoloc: {
    lat: number
    lng: number
  }
  distance: number
  tier: string
}

type LocationAlgoliaHit = {
  objectID: string
  slug: string
  title: string
  avatar: string
  address: string
  doctors: Array<string>
  regions: Array<string>
  _geoloc: {
    lat: number
    lng: number
  }
  _rankingInfo: {
    geoDistance: number
    matchedGeoLocation: {
      lat: number
      lng: number
    }
  }
  locationTier?: string
}

export const locationsContextReducer = async (
  settings: Settings,
  siteInfo: SiteInfo | null,
  appContext: AppState
) => {
  const algoliaInstance = algolia(settings, siteInfo, appContext)

  if (appContext.query.lat && appContext.query.lng) {
    try {
      const { hits } = await algoliaInstance.locations()
      const hitsTyped = hits as unknown as Array<LocationAlgoliaHit>
      const payload: Array<Location> = hitsTyped.map(
        (hit: LocationAlgoliaHit) => {
          return {
            id: Number(hit.objectID),
            slug: hit.slug,
            title: hit.title,
            avatar: avatar('location', hit.avatar, siteInfo?.layout ?? 'icl'),
            address: hit.address,
            doctors: hit.doctors.map((v) => Number(v)),
            regions: hit.regions.map((v) => Number(v)),
            tier: hit.locationTier ?? 'none',
            _geoloc: hit._geoloc,
            distance: Math.round(
              hit._rankingInfo.geoDistance / settings.unit.value
            ),
          }
        }
      )
      if (appContext.query.sort === 'experience' && siteInfo?.isIN) {
        type TieredSortingType = {
          tier1: Array<Location>
          tier2: Array<Location>
          tier3: Array<Location>
          tier4: Array<Location>
          tier5: Array<Location>
          tier6: Array<Location>
          none: Array<Location>
        }

        const tieredSorting: TieredSortingType = {
          tier1: [],
          tier2: [],
          tier3: [],
          tier4: [],
          tier5: [],
          tier6: [],
          none: [],
        }

        type TieredSortingKey = keyof typeof tieredSorting

        for (let i = 0; i < payload.length; i++) {
          const location = payload[i]
          tieredSorting[location.tier as TieredSortingKey].push(location)
        }

        return [
          ...shuffle(tieredSorting.tier1),
          ...shuffle(tieredSorting.tier2),
          ...shuffle(tieredSorting.tier3),
          ...shuffle(tieredSorting.tier4),
          ...shuffle(tieredSorting.tier5),
          ...tieredSorting.none,
        ]
      } else {
        return payload
      }
    } catch (error) {
      console.error(error)
      return []
    }
  } else {
    return []
  }
}
