import { SiteInfo } from 'shared'
import algolia from '../../helpers/algolia'
import avatar from '../../helpers/avatar'
import { Settings } from '../../settings-types'
import { AppState } from '../app'

export type Doctor = {
  id: number
  slug: string
  title: string
  avatar: string
  specialist: boolean
  subtitle: string
  address: string
  priority: number
  locations: Array<number>
  doctorPicture?: Array<{
    url: string
  }>
  doctorGallery?: Array<{
    thumb: string
    image: string
  }>
  doctorVideo?: Array<{
    embeddedAsset: {
      code: string
    }
  }>
  regions: Array<number>
  _geoloc: Array<{
    lat: number
    lng: number
  }>
  doctorEmail?: string
  distance: number
  tier: string
  doctorCustomReviewLink?: string | null
  doctorCustomReviewText?: string | null
  doctorGoogleReviewLink?: string | null
  doctorReviewId?: string | null
  doctorReviewType?: string | null
  freeConsultation?: boolean
  consultationUrl?: string | null
  customFormVerbiage?: string | null
  locationPhone?: {
    url: string
  }
}

type DoctorAlgoliaHit = {
  objectID: number
  slug: string
  title: string
  avatar: string
  specialist: boolean
  subtitle: Array<string>
  address: Array<string>
  doctorPriority: Array<string>
  locations: Array<string>
  _geoloc: Array<{
    lat: number
    lng: number
  }>
  regions: Array<string>
  tier: string
  _rankingInfo: {
    geoDistance: number
    matchedGeoLocation: {
      lat: number
      lng: number
    }
  }
  doctorCustomReviewLink?: string | null
  doctorCustomReviewText?: string | null
  doctorGoogleReviewLink?: string | null
  doctorReviewId?: string | null
  doctorReviewType?: string | null
  locationPhone?: Array<string>
}

export const doctorsContextReducer = async (
  settings: Settings,
  siteInfo: SiteInfo | null,
  appContext: AppState
) => {
  if (appContext.query.lat && appContext.query.lng) {
    const algoliaInstance = algolia(settings, siteInfo, appContext)

    try {
      const { hits } = await algoliaInstance.doctors()
      const hitsTyped = hits as unknown as Array<DoctorAlgoliaHit>
      const payload: Array<Doctor> = hitsTyped.map((hit: DoctorAlgoliaHit) => {
        let geolocIndex = 0

        if (hit.locations.length > 1) {
          geolocIndex = hit._geoloc.findIndex(
            (v) =>
              v.lat.toFixed(2) ===
                hit._rankingInfo.matchedGeoLocation.lat.toFixed(2) &&
              v.lng.toFixed(2) ===
                hit._rankingInfo.matchedGeoLocation.lng.toFixed(2)
          )

          if (geolocIndex === -1) {
            geolocIndex = 0
          }
        }

        const locationPhone = (hit.locationPhone?.[0] ?? '').replace(
          /^tel:/,
          ''
        )

        return {
          id: Number(hit.objectID),
          slug: hit.slug,
          title: hit.title,
          avatar: avatar('doctor', hit.avatar, siteInfo?.layout ?? 'icl'),
          specialist: hit.specialist,
          subtitle: hit.subtitle ? hit.subtitle[geolocIndex] : '',
          address: hit.address ? hit.address[geolocIndex] : '',
          priority: hit.doctorPriority ? Number(hit.doctorPriority) : 99999,
          locations: hit.locations.map((v) => Number(v)),
          regions: hit.regions.map((v) => Number(v)),
          _geoloc: hit._geoloc,
          distance: Math.round(
            hit._rankingInfo.geoDistance / settings.unit.value
          ),
          tier: hit.tier ? hit.tier : 'none',
          doctorCustomReviewLink: hit.doctorCustomReviewLink,
          doctorCustomReviewText: hit.doctorCustomReviewText,
          doctorGoogleReviewLink: hit.doctorGoogleReviewLink,
          doctorReviewId: hit.doctorReviewId,
          doctorReviewType: hit.doctorReviewType,
          locationPhone: { url: locationPhone },
        }
      })

      if (appContext.query.sort === 'tiered') {
        type TieredSortingType = {
          tier1: Array<Doctor>
          tier2: Array<Doctor>
          tier3: Array<Doctor>
          tier4: Array<Doctor>
          tier5: Array<Doctor>
          none: Array<Doctor>
        }

        const tieredSorting: TieredSortingType = {
          tier1: [],
          tier2: [],
          tier3: [],
          tier4: [],
          tier5: [],
          none: [],
        }

        type TieredSortingKey = keyof typeof tieredSorting

        for (let i = 0; i < payload.length; i++) {
          const doctor = payload[i]
          tieredSorting[doctor.tier as TieredSortingKey].push(doctor)
        }

        const randomizedResults = [
          ...shuffle(tieredSorting.tier1),
          ...shuffle(tieredSorting.tier2),
          ...shuffle(tieredSorting.tier3),
          ...shuffle(tieredSorting.tier4),
          ...shuffle(tieredSorting.tier5),
          ...tieredSorting.none,
        ]

        return randomizedResults
      } else {
        return payload
      }
    } catch (error) {
      console.error(error)
      return []
    }
  } else {
    return []
  }
}

export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length
  let randomIndex = 0

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}
