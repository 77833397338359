import { createClient, fetchExchange } from '@urql/core'
import { cacheExchange } from '@urql/exchange-graphcache'
import schema from './schema.json'

const discoverIclClients: {
  [localeHandle: string]: ReturnType<typeof createClient>
} = {}

const schemaString = JSON.stringify(schema) || '{}'

const discoverIclClient = (localeHandle: string = 'unitedStates') => {
  if (!discoverIclClients[localeHandle]) {
    discoverIclClients[localeHandle] = createClient({
      url: process.env.NEXT_PUBLIC_DOCTORS_DISCOVERICL_GRAPHQL_URL ?? '',
      exchanges: [
        fetchExchange,
        cacheExchange({ schema: JSON.parse(schemaString) }),
      ],
    })
  }

  return discoverIclClients[localeHandle]
}

const doctorsEvoClient = createClient({
  url: process.env.NEXT_PUBLIC_DOCTORS_EVO_GRAPHQL_URL ?? '',
  exchanges: [fetchExchange],
})

const doctorsIclClient = createClient({
  url: process.env.NEXT_PUBLIC_DOCTORS_ICL_GRAPHQL_URL ?? '',
  exchanges: [fetchExchange],
})

export { discoverIclClient, doctorsEvoClient, doctorsIclClient }
