const avatar = (type: string, image: string, layout: string) => {
  const avatars = {
    evo: {
      doctor: '/img/doctor-placeholder.svg',
      location: '/img/location-placeholder.svg',
    },
    icl: {
      doctor: '/img/doctor-placeholder-icl.svg',
      location: '/img/location-placeholder-icl.svg',
    },
  }

  type KeyLayout = keyof typeof avatars
  type KeyType = keyof typeof avatars.icl

  return image || avatars[layout as KeyLayout][type as KeyType]
}

export default avatar
