import algoliasearch, { SearchIndex } from 'algoliasearch'
import { AppState } from '../context/app'
import { Settings } from '../settings-types'
import { SiteInfo } from 'shared'

type Index = {
  name: string
  index: SearchIndex
}

const algolia = (
  settings: Settings,
  siteInfo: SiteInfo | null,
  appContext: AppState
) => {
  const client = algoliasearch(
    process.env.NEXT_PUBLIC_DOCTORS_ALGOLIA_APP_ID ?? '',
    process.env.NEXT_PUBLIC_DOCTORS_ALGOLIA_API_KEY ?? ''
  )

  const indexes: Array<Index> = []

  const region = () => {
    return appContext.regions.find((v) => v.slug === appContext.query.q)
  }

  const radius = () => {
    // there are some cases where radius label and value are not the same
    // so we have to make sure we use correct value even for default radius
    const radiusLabel = appContext.query.radius || appContext.radius.default
    const radiusValue = appContext.radius.options.find(
      (v) => v.label === radiusLabel
    )?.value

    return radiusValue || radiusLabel
  }

  const sort = () => {
    // tiered sorting happens later in doctors reducer and we need to fetch
    // doctor list from Algolia sorted by experience first
    return settings.sort.options.find(
      (v) =>
        v.label ===
        (appContext.query.sort === 'tiered'
          ? 'experience'
          : appContext.query.sort)
    )
  }

  const index = (name: string): SearchIndex => {
    const found = indexes.find((index) => {
      return index.name === name
    })

    if (!found) {
      const newIndex = client.initIndex(name)

      indexes.push({
        name,
        index: newIndex,
      })

      return newIndex
    } else {
      return found.index
    }
  }

  const name = (type: string, suffix: string) => {
    return `${siteInfo?.layout}-${siteInfo?.iso}-${type}-${suffix}`
  }

  const options = (search: string = '') => {
    const defaults = {
      aroundLatLng: `${appContext.query.lat},${appContext.query.lng}`,
      getRankingInfo: true,
      hitsPerPage: 500,
    }

    switch (search) {
      case 'regions':
        return {
          ...defaults,
          filters: `regions:${region()?.id}`,
        }

      case 'geocoding':
        return {
          ...defaults,
          aroundRadius: Math.ceil(
            parseInt(radius() ?? '1') * settings.unit.value
          ),
        }

      default:
        return {
          ...defaults,
          hitsPerPage: 1,
        }
    }
  }

  const doctors = () => {
    const suffix = settings.rankPerRegion
      ? 'region' + region()?.id
      : sort()?.value
    return index(name('doctors', suffix ?? ''))?.search(
      '',
      options(settings.search)
    )
  }

  const locations = () => {
    return index(name('locations', sort()?.value ?? 'experience')).search(
      '',
      options(settings.search)
    )
  }

  const unbounded = () => {
    return index(name('locations', 'proximity'))?.search('', options())
  }

  return { doctors, locations, unbounded }
}

export default algolia
